<template>
  <div>
    <div class="box" v-drag>
      <div>船 舶 类 型 图 例</div>

      <div class="main">
          <div class="item">
          <div class="item-color" :style="{'background-color': color[0]}"></div>
          <div class="item-name">军舰</div>
        </div>

        <div class="item">
          <div class="item-color" :style="{'background-color': color[1]}"></div>
          <div class="item-name">海警船</div>
        </div>

        <div class="item">
          <div class="item-color" :style="{'background-color': color[2]}"></div>
          <div class="item-name">作业船</div>
        </div>
        
        <div class="item">
          <div class="item-color" :style="{'background-color': color[3]}"></div>
          <div class="item-name">特种船</div>
        </div>
        
        <div class="item">
          <div class="item-color" :style="{'background-color': color[4]}"></div>
          <div class="item-name">客船</div>
        </div>
        
        <div class="item">
          <div class="item-color" :style="{'background-color': color[5]}"></div>
          <div class="item-name">货船</div>
        </div>
        
        <div class="item">
          <div class="item-color" :style="{'background-color': color[6]}"></div>
          <div class="item-name">油船</div>
        </div>

        <div class="item">
          <div class="item-color" :style="{'background-color': color[7]}"></div>
          <div class="item-name">其他</div>
        </div>
        
      </div>

      

      
      <!-- <div class="line">
        <div class="lable">融合轨迹</div>
        <legend-line styleFlag="dashed" circleColor="#FF6600" lineColor="#333333" />
        <el-switch class="switch"
          v-model="mixValue"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="mixChange"
        >
        </el-switch>
      </div>

      <div class="line">
        <div class="lable"> AIS 轨迹</div>
        <legend-line styleFlag="line" circleColor="lightgreen" lineColor="#FF0011" />
        <el-switch class="switch"
          :disabled="mixValue"
          v-model="aisValue"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="aisChange"
        >
        </el-switch>
      </div>
      
      <div class="line">
        <div class="lable">开源轨迹</div>

        <legend-line  styleFlag="dashed" circleColor="#ecb13c" lineColor="#16919a" />

        <el-switch class="switch"
          :disabled="mixValue"
          v-model="opsValue"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="opsChange" 
        >
        </el-switch>
      </div> -->

            
    </div>
  </div>
</template>

<script>
import vDrag from "../utils/drag.js";
// import LegendLine from "./legendLine.vue";
export default {
  components: {
    // LegendLine,
  },
  // props: ["mixValue1", "aisValue1", "opsValue1"],
  data() {
    return {
      // mixValue: true,
      // aisValue: false,
      // opsValue: false,
      color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
      ]
    };
  },
  computed: {
    // getLineStyle() {
    //   return {
    //     "margin-left": "-2px",
    //     "background-image": `linear-gradient(to right, ${this.lineColor} 50%, rgba(255,255,255,0) 0%)` /* 50%设置虚线点x轴上的长度 */,
    //   };
    // },
    // getCircleStyle() {
    //   return {
    //     background: this.circleColor,
    //   };
    // },
  },
  methods: {
    // mixChange(newValue) {
    //   if(newValue) {
    //     this.opsValue = false;
    //     this.aisValue = false;
    //   }else {
    //     this.opsValue = true;
    //     this.aisValue = true;
    //   }
    //   this.$emit('mixChange', newValue)
    // },
    // opsChange(newValue) {
    //   this.$emit('opsChange', newValue)
    // },
    // aisChange(newValue) {
    //   this.$emit('aisChange', newValue)
    // }
  },
  mounted() {
    // this.mixChange(true); 
  },
  directives: {
    drag: vDrag,
  },
};
</script>

<style lang="less" scoped>
.box {
  position: absolute;
  top: 52px;
  right: 57px;
  width: 260px;
  height: 200px;
  padding: 20px;
  text-align: center;
  color: aliceblue;
  font-size: 17px;
  background-color: rgba(0, 0, 0, 0.56);
  z-index: 199999;
    box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
          2px 0 3px -1px gray;
    // box-shadow: 8px 8px 5px #888888;
    border-radius: 10px;

  .lable{
    font-size: 13px;
    margin-right: 20px;
  }

  .line {
    margin-top: 30px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switch {
      margin-left: 30px;
    }
  }

  .item{
    display: flex;
    .item-color{
      margin-left: 12px;
      border-radius: 3px;
      width: 50px;
      height: 20px;
    }
    .item-name{
      margin-right: 20px;
      margin-left: 5px;
      width: 39px;
      font-size: 12px;
    }
  }

  .main {
    width: 260px;
    height: 190px;
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    flex-wrap: wrap;
  }

}
</style>
